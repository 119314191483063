<template>
    <div class="statistics_all">
        <div class="filter">
            <!--<div class="item">
                <div class="left">截止时间</div>
                <el-date-picker size="medium" v-model="end_time" type="month" placeholder="选择截止月度" value-format="yyyy-MM"></el-date-picker>
            </div>-->
            <!--<div class="item">
                <div class="left">所属年度</div>
                <el-date-picker size="medium" v-model="year" type="year" value-format="yyyy" placeholder="请选择项目年度"></el-date-picker>
            </div>-->
            <div class="item">
                <div class="left">所属地区</div>
                <el-select size="medium" v-model="area" popper-class="global_select_popper" :disabled="statistics_power.area>0">
                    <el-option v-for="item in areaList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </div>
            <div class="item">
                <div class="left">建设性质</div>
                <el-select size="medium" v-model="build_quality" popper-class="global_select_popper">
                    <el-option v-for="item in buildQuality" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </div>
            <div class="item">
                <div class="left">项目性质</div>
                <el-select size="medium" v-model="project_quality" popper-class="global_select_popper">
                    <el-option v-for="item in projectQuality" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </div>
            <div class="item">
                <div class="left">项目类别</div>
                <el-select size="medium" v-model="project_genre" popper-class="global_select_popper">
                    <el-option v-for="item in projectGenre" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </div>
            <div class="item">
                <div class="left">建设周期</div>
                <el-select size="medium" v-model="build_time_count" popper-class="global_select_popper">
                    <el-option v-for="item in buildTimeCount" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </div>
            <div class="item">
                <div class="left">项目状态</div>
                <el-select size="medium" v-model="project_status" popper-class="global_select_popper">
                    <el-option v-for="item in projectStatus" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </div>
            <div class="item">
                <div class="left">开工状态</div>
                <el-select size="medium" v-model="project_status_a" popper-class="global_select_popper">
                    <el-option v-for="item in projectStatusA" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </div>
            <div class="item btn">
                <el-button size="small" type="primary" class="button_88px" @click="search">查询</el-button>
                <el-button size="small" type="primary" class="button_88px" @click="exportStatic">导出</el-button>
            </div>
        </div>
        <div class="table">
            <el-table :data="tableData" style="width:100%" height="100%" v-loading="loading">
                <el-table-column label="序号" align="center">
                    <template slot-scope="scope">
                        <span :style="{color:apiReturnColor(scope.row.color)}">{{scope.row.project_number}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="project_name" label="项目名称" align="center"></el-table-column>
                <el-table-column label="截止时间" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.end_time">{{scope.row.end_time.slice(0,4)}}年{{scope.row.end_time.slice(5,7)}}月</span>
                    </template>
                </el-table-column>
                <el-table-column label="所属地区" align="center">
                    <template slot-scope="scope">
                        <span>{{initArea(scope.row.area)}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="建设性质" align="center">
                    <template slot-scope="scope">
                        <span>{{initBuildQuality(scope.row.build_quality)}}<!--<span v-if="scope.row.build_quality==2&&scope.row.start_time&&scope.row.end_time">({{scope.row.start_time.slice(0,4)}}年{{scope.row.start_time.slice(5,7)}}月-{{scope.row.end_time.slice(0,4)}}年{{scope.row.end_time.slice(5,7)}}月)</span>--></span>
                    </template>
                </el-table-column>
                <el-table-column label="项目性质" align="center">
                    <template slot-scope="scope">
                        <span>{{initProjectQuality(scope.row.project_quality)}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="项目类别" align="center">
                    <template slot-scope="scope">
                        <span>{{initProjectGenre(scope.row.project_genre)}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="建设周期" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.start_time">{{initYears([scope.row.start_time,scope.row.end_time]).length}}年</span>
                    </template>
                </el-table-column>
                <el-table-column label="项目拨付款(万元)" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.app_money!==null&&scope.row.app_money!==undefined&&scope.row.app_money!==''">{{Number(scope.row.app_money)/10000}}万元</span>
                    </template>
                </el-table-column>
                <el-table-column label="项目状态" align="center">
                    <template slot-scope="scope">
                        <span>{{initProjectStatus(scope.row.project_status)}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <projectDetail :enter="2" :project_detail="scope.row" @refresh="projectList"></projectDetail>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination @current-change="pageChange" :current-page="page" :page-size="pagesize" layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '@/api/methods/index';
    import computedData from '@/mixins/computedData.js';
    import customColor from '@/mixins/customColor.js';
    import projectDetail from '@c/project/project_detail.vue'
    export default {
        mixins:[computedData,customColor],
        components:{projectDetail},
        data(){
            return{
                tableData: [], page:1,pagesize:15,total:0, loading:false,
                end_time:'',year:new Date().getFullYear().toString(),
                area:0,
                areaList:[
                    {value:0, label:'全部(默认)'},
                    {value:1, label:'州本级'},
                    {value:2, label:'玛沁县'},
                    {value:5, label:'甘德县'},
                    {value:6, label:'达日县'},
                    {value:4, label:'班玛县'},
                    {value:3, label:'久治县'},
                    {value:7, label:'玛多县'},
                ],
                build_quality:0,
                buildQuality:[
                    {value:0, label:'全部(默认)'},
                    {value:1,label:'新建'},
                    {value:2,label:'续建'},
                    {value:3,label:'其它'},
                    {value:4,label:'完工未竣工'},
                    {value:5,label:'跨年'},
                ],
                project_quality:0,
                projectQuality:[
                    {value:0, label:'全部(默认)'},
                    {value:1,label:'固定资产投资类'},
                    {value:2,label:'非固定资产投资类'},
                    {value:3,label:'其他'}
                ],
                project_genre:0,
                projectGenre:[
                    {value:0, label:'全部(默认)'},
                    {value:1,label:'保障和改善民生'},
                    {value:2,label:'文化教育支援'},
                    {value:3,label:'产业支援促进就业'},
                    {value:4,label:'智力支援'},
                    {value:5,label:'交往交流交融'},
                    // {value:6,label:'基层组织和政权建设'}
                ],
                build_time_count:0,
                buildTimeCount:[
                    {value:0, label:'全部(默认)'},
                    {value:1, label:'一年项目'},
                    {value:2, label:'两年项目'},
                    {value:3, label:'两年以上项目'}
                ],
                project_status:0,
                projectStatus:[
                    {value:0, label:'全部(默认)'},
                    {value:1, label:'项目未开始实施'},
                    {value:2, label:'前期准备过程中'},
                    {value:3, label:'项目开始实施'},
                    {value:4, label:'项目进程过半'},
                    {value:5, label:'完工未验收'},
                    {value:6, label:'竣工验收'},
                    {value:7, label:'终止项目'},
                    {value:8, label:'续建项目'},
                    // {value:9, label:'变更项目'}
                ],
                project_status_a:0,
                projectStatusA:[
                    {value:0, label:'全部(默认)'},
                    {value:1, label:'未开工'},
                    {value:2, label:'已开工'},
                    {value:3, label:'已完工'},
                ],
                param:{},//点击查询后, 导出使用到的param
            }
        },
        created(){
            if(this.statistics_power.area>0){
                this.area=this.statistics_power.area;
            }
            this.projectList()
        },
        computed:{
            //time:[start_time,end_time]
            initYears:()=>(time)=>{
                let years=[];
                let start=time[0].slice(0,4);
                let end=time[1].slice(0,4);
                for(let i=start;i<=end;i++){
                    years.push(i);
                }
                return years;
            },
            statistics_power() {
                return this.$store.state.user.current_user.statistics_power
            },
            current_userid() {
                return this.$store.state.user.current_user.userid
            }
        },
        methods:{
            projectList(){
                this.loading=true;
                this.tableData=[];
                let param={page:this.page,pagesize:this.pagesize};
                if(this.area) param.area=this.area;
                if(this.build_quality) param.build_quality=this.build_quality;
                if(this.project_quality) param.project_quality=this.project_quality;
                if(this.project_genre) param.project_genre=this.project_genre;
                if(this.project_status) param.project_status=this.project_status;
                if(this.build_time_count) param.build_time_count=this.build_time_count;
                // if(this.end_time) param.end_time=this.end_time;
                // if(this.year) param.year=this.year;
                if(this.project_status_a) param.project_status_a=this.project_status_a;
                if (!this.statistics_power.area) {
                    param.fuze_users = this.current_userid;
                }
                // if (this.statistics_power.area === 1) {
                //     param.fuze_users = this.current_userid;
                // }
                this.param=param;
                api.project.projectList(param).then(res=>{
                    if(res.data){
                        this.total=res.data.count||0;
                        this.tableData=res.data.lists||[];
                    }
                    this.loading=false;
                })
            },
            pageChange(page){
                this.page=page;
                this.projectList();
            },
            search(){
                this.page=1;
                this.projectList();
            },
            //组合get参数的方法
            getQsValue(param){
                let qs = [];
                for(let property in param){
                    let value = param[property];
                    if (value != null) qs.push(property + '=' + value)
                }
                return qs.join('&')
            },
            exportStatic(){
                if(this.tableData.length===0){
                    this.$message({message:'没有可导出的数据!',type:'warning'});
                    return;
                }
                let _url = window.location.href;
                let __url = _url.split("#")[0];
                let url=__url+"index.php/cqh/Project/projectListout/?token=" + this.$store.state.user.token + "&" + this.getQsValue(this.param);
                let self=this;
                api.project.projectListout(this.param).then(res=>{
                    dd.biz.util.openLink({
                        url, //要打开链接的地址
                        onSuccess: function(result) {
                            console.log('打开导出地址成功')
                            self.show=false;
                        },
                        onFail: function(err) {
                            console.log('打开导出地址失败',err);
                            self.show=false;
                        }
                    });
                })
            }
        }
    }
</script>

<style scoped lang="less">
.statistics_all{
    height:100%;
    display:flex;
    flex-direction: column;
    .filter{
        display:flex;
        flex-wrap: wrap;
        padding-top:10px;
        .item{
            margin-bottom:10px;
            display:flex;
            align-items: center;
            flex:0 0 20%;
            min-width: 215px;
            margin-right: 30px;
            &.btn{
                flex:0 0 88px;
            }
            .left{
                flex:0 0 75px;
            }
            /deep/ .el-date-editor.el-input{
                width:100%;
            }
        }
    }
    .table{
        flex:1;
        min-height: 0;
    }
}
</style>